import React, { Component } from 'react';

import './styles.css';

import Header from '../../components/Header';

import imgLocation from '../../../src/assets/image/location.png'

import imgGerence from '../../../src/assets/image/GerenceLogo.png'

import status from '../../status.mp3'

import api from '../../services/api'
import { BASE_URL } from '../../constants/BASE_URL';

class ScreenStatusOrder extends Component {
    constructor(props) {
        super(props);

        let empresa = localStorage.getItem("@comer-aqui/empresa");

        empresa = JSON.parse(empresa);

        this.state = {
            client: props.location.state.client,
            verifyStatusOrder: true,
            statusOrder: "P",
            motivo: "",
            idpedido: props.location.state.idpedido,
            empresa,
            total: props.location.state.total,
            buscarOuEntregarAqui: props.location.state.buscarOuEntregarAqui
        }
    }    

    async componentDidMount() {
        console.log("buscarOuEntregarAqui", this.state.buscarOuEntregarAqui);

        this.setState({
            innerHeight: window.innerHeight * 0.01
        }, () => {
            document.documentElement.style.setProperty('--vh', `${this.state.innerHeight * 100}px`)
        })

        window.addEventListener('resize', () => {
            this.setState({
                innerHeight: window.innerHeight * 0.01
            }, () => {
                document.documentElement.style.setProperty('--vh', `${this.state.innerHeight * 100}px`)
            })
        });

        this.verifyStatusOrder();

        let { empresa } = this.state;

        let { qtSegundosbuscastatus, qtSegundoscancelapedido } = empresa

        if (!qtSegundosbuscastatus || qtSegundosbuscastatus == 0) {
            qtSegundosbuscastatus = 30000;
        } else {
            qtSegundosbuscastatus = `${qtSegundosbuscastatus}000`
        }

        if (!qtSegundoscancelapedido || qtSegundoscancelapedido == 0) {
            qtSegundoscancelapedido = 60000 * 3;
        } else {
            qtSegundoscancelapedido = `${qtSegundoscancelapedido}000`
        }

        setInterval(() => this.verifyStatusOrder(), qtSegundosbuscastatus)

        setTimeout(() => this.cancelStatusOrder(), qtSegundoscancelapedido)
    }

    cancelStatusOrder = async () => {
        try {
            let { idpedido, statusOrder, empresa } = this.state;

            if (statusOrder == "P") {
                let params = {
                    idempresa: empresa.codEmpresa,
                    idpedido,
                    situacao: "C",
                    motivo: "Loja não recebeu"
                }

                await api.post(`/statuspedido?idempresa=${params.idempresa}&idpedido=${params.idpedido}&situacao=${params.situacao}&motivo=${params.motivo}`);

                this.verifyStatusOrder();
            }
        } catch (erro) {
            //
            console.log(erro)

        }
    }

    verifyStatusOrder = async () => {
        let { idpedido, verifyStatusOrder, statusOrder, empresa } = this.state;

        if (verifyStatusOrder) {

            let that = this;

            let baseUrl = BASE_URL

            fetch(`${baseUrl}/statuspedido?idempresa=${empresa.codEmpresa}&idpedido=${idpedido}`)
                .then(response => response.json()).then(function handleData(data) {
                    let { motivo, situacao } = data;

                    if (situacao == "E" || situacao == "C") {
                        that.setState({ verifyStatusOrder: false });
                    }

                    if (situacao != statusOrder) {
                        let audio = new Audio(status);
                        audio.play();
                    }

                    that.setState({ statusOrder: situacao, motivo });
                }).catch(function handleError(error) { })
        }
    }

    render() {
        let { client, statusOrder, motivo } = this.state;

        return (
            <>
                <Header apenasInfos={true} />
                <div className="container-main">
                    <section className="text-center" id="header-info">
                        <h5 className="m-0">{client.name}</h5>
                        <h3 className="m-0">{client.phone}</h3>
                        <h4>Mantenha essa página aberta, para acompanhar o seu pedido</h4>
                    </section>
                    <main>
                        <h2 className="m-0">Status</h2>
                        {statusOrder == "P" && <h3 className="m-0">Pedido pendente</h3>}
                        {statusOrder == "P" && <span className="status-texto">Aguardando a loja receber o seu pedido!</span>}
                        {statusOrder == "I" && <h3 className="m-0">Pedido recebido</h3>}
                        {statusOrder == "I" && <span className="status-texto">Não se preocupe a loja já recebeu o seu pedido!</span>}
                        {statusOrder == "I" && <span className="status-icone" role="img" aria-label="smile"> <br /> 😃 </span>}
                        {statusOrder == "E" && <h3 className="text-success m-0">Pedido em entrega</h3>}
                        {statusOrder == "E" && <span className="status-texto">Oba! seu pedido já está com o entregador!</span>}
                        {statusOrder == "E" && <span className="status-icone" role="img" aria-label="smile"> <br /> 😋 </span>}
                        {statusOrder == "C" && <h3 className="text-danger m-0">Pedido cancelado</h3>}
                        {statusOrder == "C" && <span className="status-texto">Motivo: {motivo}</span>}
                        {statusOrder == "C" && <span className="status-icone" role="img" aria-label="smile"> <br /> 😥 </span>}
                        {statusOrder == "R" && <h3 className="text-danger m-0">Pedido recusado</h3>}
                        {statusOrder == "R" && <span className="status-texto">Por favor refaça seu pedido. Motivo: {motivo}</span>}
                        {statusOrder == "R" && <span className="status-icone" role="img" aria-label="smile"> <br /> 😥 </span>}
                    </main>
                    <footer>
                        {this.state.buscarOuEntregarAqui == 'VOU_BUSCAR' &&
                            <article className="info-address">

                                <img src={imgLocation} alt="Localização" />
                                <section>
                                    <h6>Seu pedido deverá ser retirado na loja</h6>
                                </section>
                            </article>
                        }
                        {this.state.buscarOuEntregarAqui !== 'VOU_BUSCAR' &&
                            <article className="info-address">

                                <img src={imgLocation} alt="Localização" />
                                <section>
                                    <h6>Seu pedido será entregue em:</h6>
                                    <h5>{client.address.streetname} {client.address.streetnumber}</h5>
                                </section>
                            </article>
                        }
                        <article className="footer-gerence">
                            <span>Desenvolvido por</span>
                            <img src={imgGerence} alt="Gerence Sistemas" />
                        </article>
                    </footer>
                </div>
            </>
        );
    }
}

export default ScreenStatusOrder;
