import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import './styles.css';

import Header from '../../components/Header';

import { Creators as CartCreator } from '../../store/ducks/cart';

import { FaArrowLeft, FaPlus, FaMinus, FaImage } from 'react-icons/fa';

import Currency from '../../utils/currency';
import { toast } from 'react-toastify';

let idSeq = 0;

const formatQuantity = (value) => {
    value = value.replace(".", "");
    value = value.replace(",", "");

    if (value) {
      let val = (value / 1000).toFixed(3);
      return val;
    }

    return 0;
};

class ScreenProduct extends Component {

    addItem = () => {
        idSeq++;

        let discount_takeout = 0;

        if (this.props.product.product.attrs.vlPrecoentrega > 0) {
            discount_takeout = (this.props.product.product.attrs.vlPrecoentrega - 
                                this.props.product.product.attrs.vlPreco) // Gabriel - 15/06/2022 - invertido os valores na subtração
        }

        if (discount_takeout < 0) {  // Gabriel - 15/06/2022
            discount_takeout = 0;
        }

        let inVerificaimpressora = null;

        try {
            inVerificaimpressora = this.props.product.products.product.grupo.inVerificaimpressora; 
        } catch (error) {   
            inVerificaimpressora = this.props.product.product.grupo.inVerificaimpressora;
        }

        const item = {
            //id: `PRODUTO${Math.floor(Math.random() * 256)}${idSeq++}`,
            id: `PRODUTO${idSeq}`,
            //id: idSeq,            
            ordered: this.props.cart.id,
            product: {
                id: this.props.product.product.id,
                iditem: `PRODUTO${idSeq}`,
                //iditem: idSeq,                
                name: this.props.product.product.name,
                quantity: this.state.quantity,
                promotionValue: this.props.product.product.promotion_price,
                value: localStorage.getItem('@comer-aqui/typeOrder') == 'MESA' ? this.props.product.product.attrs.vlPreco : this.props.product.product.price,
                total: localStorage.getItem('@comer-aqui/typeOrder') == 'MESA' ? this.props.product.product.attrs.vlPreco * this.state.quantity : this.props.product.product.price * this.state.quantity,
                discountTakeout: discount_takeout,
                note: this.state.note
            },
            status: 1,
            remove: this.state.remove,
            add: this.props.product.add,
            observation: this.refs.observation.value,
            inVerificaimpressora
        };

        if (item.product.promotionValue > 0) {
            item.product.value = item.product.promotionValue;
        }

        if (item.product.note) {
            for (let observacao of item.product.note) {
                for (let obs of observacao.itens) {
                    item.product.value += obs.valor
                }
            }
        }
        
        if (item.add) {
            for (let adicional of item.add) {
                item.product.value += adicional.value 
            }
        }

        item.product.total = item.product.value * item.product.quantity;

        return item;
    }


    handleSubmit = e => {
        e.preventDefault();

        let trava = false
        // console.log(this.state.quantity)

        // console.log(this?.props?.product?.product?.attrs?.qtMinima)

        if (this.state.quantity <= 0) {
            trava = true;
            toast.error('A quantidade precisa ser maior que 0');
        }

        if(this?.props?.product?.product?.attrs?.qtMinima > 0){

            if(this.state.quantity < this?.props?.product?.product?.attrs?.qtMinima ){
                trava = true
                toast.error(`Para compra esse item a quantidade minima é de ${this?.props?.product?.product?.attrs?.qtMinima}`)
            }
        }


      if(!trava){
        if (this.props.cart.id == null) {
            const numberOrder = localStorage.getItem('@comer-aqui/numberOrder');
            const typeOrder = localStorage.getItem('@comer-aqui/typeOrder');
            this.props.newCart(typeOrder, numberOrder);
        }

        const itemAdd = this.addItem();

        let items = [];

        items.push(itemAdd);

        this.props.addItem(itemAdd);

        this.props.history.push({
            pathname: '/cart',
            data: items,
            telaAnterior: "product"
        });
      }
    }

    handleBack = () => {
        // let { note, adicionais, telaAnterior } = this.state;

        // // this.props.history.push({
        // //     pathname: `/${telaAnterior}`,
        // //     note,
        // //     adicionais,
        // //     telaAnterior: "product"
        // // });

        // this.props.history.goBack({
        //     note,
        //     adicionais,
        //     telaAnterior: "product"
        // });

        // let empresa = localStorage.getItem('@comer-aqui/empresaUrl');
        // this.props.history.push(`/${empresa}`);
        // this.props.history.push(`/`);

        // this.props.history.setParams({
        //     note, adicionais, telaAnterior: "product"
        // });

        // console.log(localStorage.getItem('typeorder' ))

        if (localStorage.getItem('@comer-aqui/typeOrder') === "MESA") {
           // this.props.history.push({ pathname: '/' })
        //    this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl'));
           this.props.history.push('/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));


        } else {
            this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl'));

            // console.log(localStorage.getItem('@comer-aqui/empresaUrl'))

            // this.props.history.push('/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));
        }
    }

    handleInputChange = e => {
        this.refs.observation.value = e.target.value
    }

    handleClickQuantity = type => {  
        var quantity = this.state.quantity;

        if (type === 1) {
            quantity += 1;
        } else {
            if (quantity !== 1 && quantity > 1) {
                quantity -= 1;
            }
        }

        this.setState({ quantity: quantity }) 
    }

    constructor(props) {
        super(props);
        
        window.scrollTo(0, 0);

        if (!props.product.product) {
            this.props.history.push({ pathname: '/' });
            return;
        }

        let note = [];

        if (props.location.note) {
            note = props.location.note;
        }

        this.state = {
            itemsCart: [],
            observation: '',
            quantity: 1,
            note,
            adicionais: props.location.adicionais,
            remove: props.location.remove,
            telaAnterior: props.location.telaAnterior,
            errored: false,
       }

    }

    onError() {
        if (!this.state.errored) {
            this.setState({ errored: true });
        }
    }

    render() {

        const { product } = this.props;

        const { errored } = this.state;

        return (
            <>
                <Header apenasLogo={true}/>
                <div id="container" className="tela-produto">
                    <div id="content-item" className="row">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            {errored || !product.product.url ?
                                <div className="product-img">
                                    <FaImage size={50} height="150px" />
                                </div>
                                :
                                <img height={75} src={product.product.url} alt={product.product.alt} className="product-img" onError={this.onError.bind(this)} />
                            }
                        </div>
                        <div className="col-12 d-flex align-items-center justify-content-center">                            
                           
                            <h6>{this.props.product.product.name}</h6>
                        </div>
                        <div className="product-description description-product">
                            <h6>{this.props.product.product.description}</h6>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-12 text-center"><span className="title-quantity">Quantidade</span></div>
                            {this.props.product.product.attrs.idUnidade !== 2 &&
                                <div className="col-12 d-flex align-items-center justify-content-center">                                
                                    <button id="decrease-quantity" onClick={(e) => this.handleClickQuantity(2)}>
                                        <FaMinus />
                                    </button>
                                    <h6 className="m-3">{this.state.quantity}</h6>
                                    <button id="increase-quantity" onClick={(e) => this.handleClickQuantity(1)}>
                                        <FaPlus />
                                    </button>
                                </div>
                            }
                            {this.props.product.product.attrs.idUnidade === 2 &&
                                <div className="col-12 d-flex align-items-center justify-content-center">
                                    <input className='input-quantity' type='number' value={this.state.quantity} onChange={(e) => this.setState({ quantity: formatQuantity(e.target.value)})}></input> <div className='title-quantity'>Kg</div>
                                </div>
                            }
                            <div className="col-12">
                                <h6>Observação?</h6>
                                <input
                                    className="form-control"
                                    name="observation"
                                    ref="observation"
                                    placeholder="Digite aqui sua observação..."
                                    onKeyUp={this.handleInputChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="fixed-bottom">
                        <div className="row">
                            <div className="col-12 p-0">
                                <button className="btn btn-success btn-block p-3" onClick={this.handleSubmit}>
                                    Adicionar ao pedido <FaPlus />
                                </button>
                            </div>
                            <div className="col-12 p-0">
                                <button className="btn btn-dark btn-block btn-go-back" onClick={this.handleBack}>
                                    {/* voltar 2 */}
                                    <FaArrowLeft /> Voltar 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({ product: state.product, cart: state.cart });

const mapDispatchToProps = dispatch => bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenProduct);
